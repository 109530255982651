<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right">

				<el-select v-model="searchForm.school_id" @change="getTree" clearable placeholder="全部学校" size="small"
					style="width:200px;margin-left:10px">
					<el-option label="全部学校" value></el-option>
					<el-option :label="item.platform" v-for="(item, idx) in SchoolList" :key="idx"
						:value="item.id"></el-option>

				</el-select>
				<el-cascader ref="firstCascader" :options="TreeData" :props="defaultProps" :show-all-levels="true"
					v-model="searchForm.class_name" @change="getSelectType" clearable placeholder="请选择" filterable
					style="width: 250px;margin:0 10px" size="small">
				</el-cascader>

				<!-- <el-select size="small" v-model="searchForm.status" placeholder="保险购买" clearable>
					<el-option label="已购保险" :value="1"></el-option>
					<el-option label="未购保险" :value="0"></el-option>
				</el-select> -->



				<el-input placeholder="关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:150px;margin-left: 10px" clearable></el-input>



				<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
					type="primary">搜索</el-button>
				<el-button icon="el-icon-data-analysis" @click="dialogShow = true" style="margin-left:10px" size="small"
					type="primary">统计表</el-button>
				<el-button :disabled="!searchForm.school_id" title="请先选择学校" icon="el-icon-download" @click="downShenpiTable"
					style="margin-left:10px" size="small" type="primary">实习审批表</el-button>
				<el-button :disabled="!searchForm.school_id" title="请先选择学校" icon="el-icon-download" @click="downShixiTable"
					style="margin-left:10px" size="small" type="primary">实习生名册</el-button>



			</div>
			<div style="padding-top: 14px">
				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">实习列表</div>
				</div>

			</div>
		</div>
		<div class="bcontent">


			<div>
				<el-table :data="tableData" row-key="id" border stripe>
					<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
					<el-table-column label="学校" prop="platform" min-width="180" align="center"></el-table-column>
					<el-table-column label="所属系部" prop="xibu_name" width="150" align="center"></el-table-column>
					<el-table-column label="所属班级" prop="class_name" width="150" align="center"></el-table-column>
					<el-table-column label="学生姓名" prop="stu_name" min-width="150" align="center"></el-table-column>
					<el-table-column label="学号" prop="stu_code" width="150" align="center"></el-table-column>
					<el-table-column label="企业名称" prop="enter_name" min-width="150" align="center"></el-table-column>
					<el-table-column label="岗位名称" prop="job" width="150" align="center"></el-table-column>
					<el-table-column label="实习薪资" prop="salary" width="150" align="center"></el-table-column>
					<el-table-column label="企业电话" prop="enter_phone" min-width="150" align="center"></el-table-column>

					<el-table-column fixed="right" label="操作" width="80">
						<template slot-scope="scope">
							<el-button @click="viewInfo(scope.row)" type="text" size="small">详情</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div style="text-align:right;padding-top: 10px;">
					<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next"></el-pagination>
				</div>


			</div>

		</div>
		<shixitongji v-if="dialogShow" :shixi="searchForm"></shixitongji>

		<sxstuinfo v-if="infoShow" :stu_id="sxId"></sxstuinfo>


	</div>
</template>

<script>
import shixitongji from './com/sxtongji.vue'
import sxstuinfo from './com/sxstuinfo.vue'

export default {
	components: {
		shixitongji, sxstuinfo


	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			infoShow: false,
			sxId: 0,
			searchForm: {
				class_name: "",
				keyword: "",
				status: "",
				school_id: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			SchoolList: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'name',
				children: 'children',
				emitPath: false,
				checkStrictly: true,

			},
			shixi: null
		}
	},
	mounted() {
		this.getSchoolList()
		this.getDataList()
		// this.getTree()

	},
	methods: {
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				this.SchoolList = res.data
			})
		},
		getTargetPmid() {
			this.$http.post("/api/sch_qsx_target").then(res => {
				if (res.data && res.data.qsx_pmid) {
					this.TargetPmid = res.data.qsx_pmid
				}
			})

		},
		getTree() {
			this.$http.post("/api/shixi_grade_tree", { school_id: this.searchForm.school_id }).then(res => {
				this.TreeData = res.data
			})
		},
		getSelectType(e) {
			let node = this.$refs['firstCascader'].getCheckedNodes()
			if (node[0]) {
				let path = node[0].pathNodes[0].label
				if (node[0].pathNodes.length >= 2) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = node[0].pathNodes[1].label
				} else if (node[0].pathNodes.length >= 1) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = ""
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""
				}
			} else {
				this.searchForm.xibu_name = ""
				this.searchForm.class_name = ""

			}

		},
		tongbuData() {
			this.$http.post("/api/tongbu_shixi", { loading: true, tpmid: this.TargetPmid }).then(res => {
				this.$message.success("同步成功")
				this.page.current_page = 1;
				this.getDataList();
			})
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				status: this.searchForm.status,
				school_id: this.searchForm.school_id,
				showloading: true
			}
			this.$http.post("/api/shixi_list", data).then(res => {
				this.tableData = res.data.data
				this.page = res.data.page
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.sxId = e.id

			this.infoShow = true
		},
		uploadFiles(e) {
			this.$http.post("/api/import_shixixls", {
				url: e.src
			}).then(res => {

				this.getDataList()
				this.$message.success("导入成功")
				if (res.data.msg.length > 0) {
					let html = ''
					for (let msg of res.data.msg) {
						html += "<div style='font-size:12px'>" + msg.stu_name + ":" + msg.msg + "</div>"
					}
					this.$alert(html, '未导入数据', {
						dangerouslyUseHTMLString: true
					});
				}
				this.dialogshow1 = false


			})
		},
		deleteRow(e) {

			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/shixi_delete", { id: e.id }).then(res => {
					this.getDataList()
					this.$message({
						type: 'success',
						message: '删除成功!'
					});

				})
			}).catch(() => {

			});




		},
		downShenpiTable() {
			this.$message({
				type: 'success',
				message: '正在打包文件中，请稍等~'
			});
			this.$http.post("/api/shenpi_down_zip", { school_id: this.searchForm.school_id }).then(res => {
				if (res.data.file) {
					
					window.open(res.data.file, "_blank")
				} else {
					this.$message({
						type: 'error',
						message: '该学校未上传文件!'
					});
				}

			})
		},
		downShixiTable() {
			this.$message({
				type: 'success',
				message: '正在打包文件中，请稍等~'
			});
			this.$http.post("/api/shixi_down_zip", { school_id: this.searchForm.school_id }).then(res => {
				if (res.data.file) {
				
					window.open(res.data.file, "_blank")
				} else {
					this.$message({
						type: 'error',
						message: '该学校未上传文件!'
					});
				}

			})
		},

	}
}
</script>
<style scoped></style>
