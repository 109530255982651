<template>
    <div>
        <el-dialog class="cus_dialog " title="实习统计" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="80%" height="90%">
            <el-row :gutter="20">
                <el-col :span="5">
                    <div :class="['school', schoolId == 0 ? 'ckd' : '']" @click="setSchool(0)">全部学校</div>
                    <div :class="['school', schoolId == item.id ? 'ckd' : '']" @click="setSchool(item.id)"
                        v-for="(item, idx) in SchoolList" :key="idx">{{ item.platform }}</div>
                </el-col>

                <el-col :span="18">
                    <el-card>

                        <div>

                            <el-tag effect="plain" style="margin-left:10px">
                                总毕业生人数：{{ DataList.ttCount?DataList.ttCount.n :0 }}
                            </el-tag>
                            <el-tag effect="plain" style="margin-left:10px">
                                实习人数：{{ DataList.sxCount?DataList.sxCount.n :0 }}
                            </el-tag>
                            <el-tag effect="plain" style="margin-left:10px">
                                转本人数：{{ DataList.sbCount?DataList.sbCount.n :0 }}
                            </el-tag>
                            <el-tag effect="plain" style="margin-left:10px">
                                出国人数：{{ DataList.cgCount?DataList.cgCount.n :0 }}
                            </el-tag>
                            <el-tag effect="plain" style="margin-left:10px">
                                参军人数：{{ DataList.cjCount?DataList.cjCount.n :0 }}
                            </el-tag>

                        </div>

                        <div>
                            <div id="chart_pie1"
                                style="width:50%; height: 300px; display: inline-block;vertical-align: top;"></div>
                            <div id="chart_pie2"
                                style="width:50%; height: 300px; display: inline-block;vertical-align: top;"></div>
                            <div id="chart_pie3"
                                style="width:50%; height: 300px; display: inline-block;vertical-align: top;"></div>
                            <div id="chart_pie4"
                                style="width:50%; height: 300px; display: inline-block;vertical-align: top;"></div>

                        </div>

                    </el-card>
                </el-col>
            </el-row>
        </el-dialog>


    </div>
</template>

<script>

export default {
    props: ["baoxian"],
    components: {

    },
    data() {
        return {
            dialogshow: true,
            saveBtnShow: false,
            schoolId: 0,
            DataList: {},
            SchoolList: []
        }
    },
    mounted() {
        this.getSchoolList()
        this.getList()
    },
    methods: {
        getSchoolList() {
            this.$http.post("/api/school_list").then(res => {
                this.SchoolList = res.data
            })
        },
        setSchool(id) {
            this.schoolId = id
        },
        getList() {

            this.$http.post("/api/shixi_tongji", this.baoxian_tongji).then(res => {
               
                this.DataList = res.data

                if(this.DataList.salary){
                    let data = this.DataList.salary.map(e=>{return {value:e.n,name:e.salary}})
                    this.initChart(data,"chart_pie1","实习薪资")
                }

                if(this.DataList.enter_type){
                    let data = this.DataList.enter_type.map(e=>{return {value:e.n,name:e.enter_type}})
                    this.initChart(data,"chart_pie2","企业性质")
                }

                if(this.DataList.duikou){
                    let data = this.DataList.duikou.map(e=>{return {value:e.n,name:e.duikou?"对口":"不对口"}})
                    this.initChart(data,"chart_pie3","对口情况")
                }

                if(this.DataList.baoxian){
                    let data = this.DataList.baoxian.map(e=>{return {value:e.n,name:e.buyok==1?'已购保险':'未购保险'}})
                    this.initChart(data,"chart_pie4","保险购买")
                }

                
            })
        },
        closeDialog() {

            this.$$parent(this, "dialogShow", false)

        },
        initChart(data,div_id,name) {

            

            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: name,
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            };
            var yjChart = this.$echarts.init(document.getElementById(div_id));
            yjChart.setOption(option);
           
        },
        exportXls() {
            this.$http.post("/api/baoxian_xls_tj", this.baoxian).then(res => {
                if (res.data.url) {
                    window.open(res.data.url, "_blank")
                    this.$message({
                        type: 'success',
                        message: '导出成功!'
                    });
                }
            })
        }



    }

}

</script>

<style scoped>
.school {
    line-height: 30px;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
}

.school:hover {
    background-color: aliceblue;

}

.ckd {
    background-color: #547df2;
    color: #fff;
}

.ckd:hover {
    background-color: #547df2;
    color: #fff;
}</style>