<template>
    <div>
        <el-dialog class="cus_dialog " title="实习详情" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="600px" height="90%">
          
            <table class="dttable">
                <tr>
                    <td class="tdheader">所属学校</td>
                    <td>{{ ckdItem.platform }}</td>
                </tr>
                <tr>
                    <td class="tdheader">姓名</td>
                    <td>{{ ckdItem.stu_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">性别</td>
                    <td>{{ ckdItem.gender }}</td>
                </tr>
                <tr>
                    <td class="tdheader">学生手机号</td>
                    <td>{{ ckdItem.stu_phone }}</td>
                </tr>
                <tr>
                    <td class="tdheader">年龄</td>
                    <td>{{ ckdItem.age }}</td>
                </tr>
                <tr>
                    <td class="tdheader">专业</td>
                    <td>{{ ckdItem.xibu_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">班级</td>
                    <td>{{ ckdItem.class_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">实习企业</td>
                    <td>{{ ckdItem.enter_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">部门</td>
                    <td>{{ ckdItem.stu_dept }}</td>
                </tr>
                <tr>
                    <td class="tdheader">职位</td>
                    <td>{{ ckdItem.job }}</td>
                </tr>
                <tr>
                    <td class="tdheader">薪资</td>
                    <td>{{ ckdItem.salary }}</td>
                </tr>
                <tr>
                    <td class="tdheader">企业联系人</td>
                    <td>{{ ckdItem.enter_phone }}</td>
                </tr>
                <tr>
                    <td class="tdheader">企业类型</td>
                    <td>{{ ckdItem.enter_type }}</td>
                </tr>
                <tr>
                    <td class="tdheader">是否录用</td>
                    <td>{{ ckdItem.luyong }}</td>
                </tr>
                <tr>
                    <td class="tdheader">是否团员</td>
                    <td>{{ ckdItem.tuanyuan }}</td>
                </tr>
               
                
                <tr>
                    <td class="tdheader">是否对口</td>
                    <td>{{ ckdItem.duikou }}</td>
                </tr>
               
                <tr>
                    <td class="tdheader">家庭地址</td>
                    <td>{{ ckdItem.jtzz }}</td>
                </tr>
                <tr>
                    <td class="tdheader">班主任</td>
                    <td>{{ ckdItem.bzr_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">班主任电话</td>
                    <td>{{ ckdItem.bzr_phone }}</td>
                </tr>
                <tr>
                    <td class="tdheader">指导老师</td>
                    <td>{{ ckdItem.zdls_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">指导老师电话</td>
                    <td>{{ ckdItem.zdls_phone }}</td>
                </tr>
                <!-- <tr>
                    <td class="tdheader">附件</td>
                    <td>{{ ckdItem.files }}</td>
                </tr> -->

                <tr>
                    <td class="tdheader">实习名册</td>
                    <td>
                        <a v-if="ckdItem.docx_url" :href="ckdItem.docx_url" target="_blank">已上传，点击下载</a>
                        <span v-else>未上传</span>
                    </td>
                </tr>
                <tr>
                    <td class="tdheader">实习审批表</td>
                    <td>
                        <a v-if="ckdItem.spbiao" :href="ckdItem.spbiao.file" target="_blank">已上传，点击下载</a>
                        <span v-else>未上传</span>
                    
                    </td>
                </tr>
            </table>

            

        </el-dialog>


        

    </div>
</template>

<script>

export default {
    props: ["stu_id"],
    components: {

    },
    data() {
        return {
            dialogshow: true,
           
            DataList: [],
            ckdItem: {}
        }
    },
    mounted() {

        this.getData()
    },
    methods: {
        getData(){
            this.$http.post("/api/shixin_info",{id:this.stu_id}).then(res=>{
                this.ckdItem = res.data
            })
        },
        closeDialog() {
            this.dialogshow = false
            this.$$parent(this,"infoShow",false)
        },
       


    }

}

</script>

<style scoped>

</style>